import { createApp } from 'vue'
import App from './App.vue'
import store from '@/framework/store'
import i18n from '@/framework/i18n'
import createAppRouter from '@/framework/router'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.less';
import '@/assets/iconfont'
// import vConsole from '@/untils/vconsole'


createApp(App).use(createAppRouter()).use(i18n).use(store).use(Antd).mount('#app');
