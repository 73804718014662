import antdLocale from 'ant-design-vue/es/locale/de_DE';

const messages = {
    http: {
        "undefined": "Bitte überprüfen Sie das Netzwerk und versuchen Sie es erneut.",
        "400": "[400] Bitte überprüfen Sie Ihren Browser und Ihr Netzwerk oder wenden Sie sich an Ihren Administrator.",
        "401": "[401] Sie sind derzeit nicht eingeloggt oder Ihr Login ist abgelaufen. Bitte melden Sie sich erneut an.",
        "403": "[403] Sie haben keine Berechtigung für den Zugriff auf diese Funktion. Bitte wenden Sie sich bei Problemen an Ihren Administrator.",
        "404": "[404] Die angeforderte Ressource existiert nicht. Bitte wenden Sie sich an Ihren Administrator.",
        "406": "[406] Bitte überprüfen Sie Ihren Browser oder wenden Sie sich an Ihren Administrator.",
        "500": "[500] Es ist ein Fehler auf dem Server aufgetreten. Bitte kontaktieren Sie Ihren Administrator, um ihn zu beheben.",
        "502": "[502] Gateway-Fehler. Das Netzwerk mit dem Server ist nicht erreichbar. Bitte wenden Sie sich an Ihren Administrator.",
        "503": "[503] Der Service ist nicht verfügbar. Der Server ist vorübergehend überlastet oder wird gewartet. Bitte versuchen Sie es später erneut.",
        "504": "[504] Das Gateway Timeout ist abgelaufen und das Netzwerk mit dem Server ist nicht erreichbar. Bitte wenden Sie sich an Ihren Administrator."
    },
    language: {
        "--": "Automatisch",
        "zh": "中文",
        "en": "English",
        "fr": "Français",
        "ja": "日本語"
    },
    login: {
        "failed": "Bitte bestätigen Sie und loggen Sie sich erneut ein.",
        "status": {
            "0":"Bitte versuchen Sie es später noch einmal.\n\nBei Fragen wenden Sie sich bitte an Ihren Administrator."
        }
    }
}

export default {
    messages,
    antdLocale
}