import antdLocale from 'ant-design-vue/es/locale/ja_JP';

const messages = {
    http: {
        "undefined": "ネットワーク要求がタイムアウトしました。ネットワークを確認して再試行してください。",
        "400": "[400] 送信された要求にエラーがありました。ブラウザとネットワークを確認するか、管理者に連絡してください。",
        "401": "[401] 現在ログインしていないか、ログインがタイムアウトしているので、再度ログインしてください。",
        "403": "[403] 現在、この機能にアクセスする権限がありません。問題があれば管理者に連絡してください。",
        "404": "[404] 要求されたリソースは存在しません。管理者に連絡してください。",
        "406": "[406] 要求されたフォーマットが無効です。ブラウザを確認するか、管理者に連絡してください。",
        "500": "[500] サーバーでエラーが発生しました。管理者に連絡して解決してください。",
        "502": "[502] ゲートウェイエラー、サーバとのネットワークにアクセスできません。管理者にお問い合わせください。",
        "503": "[503] サービスは使用できません。サーバーは一時的に過負荷またはメンテナンス中です。後で再試行してください。",
        "504": "[504] ゲートウェイがタイムアウトしました。サーバとのネットワークにアクセスできません。管理者にお問い合わせください。"
    },
    language: {
        "--": "自動選択",
        "zh": "中文",
        "en": "English",
        "fr": "Français",
        "ja": "日本語"
    },
    login: {
        "failed": "アカウントまたはパスワードが正しくありません。確認して再ログインしてください。",
        "status": {
            "0":"アカウントはレビュー中ですので、後で再試行してください。\n\n何か質問があれば管理者にお問い合わせください。"
        }
    }
}

export default {
    messages,
    antdLocale
}