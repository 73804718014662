import { createRouter, createWebHashHistory } from 'vue-router'
import routes from '@/routes.js'
import { permission } from './permission'
import appConfig from "../config";

const joinUrl = (base, sub) => {
    if (base == null || sub == null) return base ?? sub ?? '';
    const bes = base.endsWith('/');
    const sss = sub.startsWith('/');
    if (bes && sss) return base + sub.substring(1);
    if (!bes && !sss) return base + '/' + sub;
    return base + sub;
}
const generateMenu = (router, _purl = '') => {
    if (router == null) return undefined;
    return router.reduce((p, item) => {
        const url = joinUrl(_purl, item.path);
        const children = generateMenu(item.children, url);
        if (item.name) {
            p.push({ name: item.name, icon: item.icon, url, children });
        } else if (children) {
            p = p.concat(children);
        }
        return p;
    }, [])
}

const createAppRouter = () => {
    const router = createRouter({
        history: createWebHashHistory('/'),
        routes: routes
    });

    const routerList = router.options.routes;
    appConfig.menu = generateMenu(routerList)

    permission(router);

    return router;
}

export default createAppRouter