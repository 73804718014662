import config from '@/app.config'
const appConfig = {
    locale: {
        // main: "zh",
        // available: ["zh","en","fr"]
    },
    router: {
        loginPath: "",
        defaultPath: "",
    },
}
Object.assign(appConfig, config)

// Locale part
if(!appConfig.locale) appConfig.locale={}
// 是否有获得语种
if(appConfig.locale.available?.length ?? 0 > 0) {
    // 如果当前语种不存在且没有获得当前语种
    if(!appConfig.locale.main || !appConfig.locale.available.includes(appConfig.locale.main)) {
        appConfig.locale.main = appConfig.locale.available[0];
    }
}

// Router part
if(!appConfig.router) appConfig.router={}
if(!appConfig.router.loginPath) appConfig.router.loginPath = '/login'
if(!appConfig.router.defultPath) appConfig.router.defultPath = '/'

export default appConfig
