import antdLocale from 'ant-design-vue/es/locale/zh_CN';

const messages = {
    http: {
        "undefined": "网络请求超时，请检查网络并重试。",
        "400": "[400] 发出的请求有错误，请检查您的浏览器及网络，或与您的管理员联系。",
        "401": "[401] 您当前未登录或登录已超时，请重新登录。",
        "403": "[403] 您当前没有访问该功能的权限，如有问题请与您的管理员联系。",
        "404": "[404] 请求的资源不存在，请与您的管理员联系。",
        "406": "[406] 请求的格式无效，请检查您的浏览器或与您的管理员联系。",
        "500": "[500] 服务器发生错误，请联系您的管理员解决。",
        "502": "[502] 网关错误，与服务器的网络不可达，请与您的管理员联系。",
        "503": "[503] 服务不可用，服务器暂时过载或维护中，请稍后再试。",
        "504": "[504] 网关超时，与服务器的网络不可达，请与您的管理员联系。"
    },
    language: {
        "--": "自动选择",
        "zh": "中文",
        "en": "English",
        "fr": "Français",
        "ja": "日本語"
    },
    login: {
        "failed": "账号或者密码不正确，请确认并重新登录。",
        "status": {
            "0":"您的账号正在审核中，请稍后再试。\n\n如果您有任何疑问请联系您的管理员。"
        }
    },
}

export default {
    messages,
    antdLocale,
}
