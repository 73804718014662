import antdLocale from 'ant-design-vue/es/locale/fr_FR';

const messages = {
    http: {
        "undefined": "La demande de réseau a expiré, veuillez vérifier le réseau et réessayer.",
        "400": "[400] Une erreur s'est produite dans la demande effectuée, veuillez vérifier votre navigateur et votre réseau, ou contactez votre administrateur.",
        "401": "[401] Vous n'êtes pas connecté actuellement ou la connexion a expiré, veuillez vous reconnecter.",
        "403": "[403] Vous n'avez actuellement pas accès à cette fonctionnalité, veuillez contacter votre administrateur si vous avez des questions.",
        "404": "[404] La ressource demandée n'existe pas, veuillez contacter votre administrateur.",
        "406": "[406] Le format demandé n'est pas valide, veuillez vérifier votre navigateur ou contacter votre administrateur.",
        "500": "[500] Une erreur s'est produite sur le serveur, veuillez contacter votre administrateur pour la résoudre.",
        "502": "[502] Erreur de passerelle, le réseau avec le serveur est inaccessible, veuillez contacter votre administrateur.",
        "503": "[503] Le service est indisponible. Le serveur est temporairement surchargé ou en maintenance. Veuillez réessayer ultérieurement.",
        "504": "[504] La passerelle a expiré et le réseau avec le serveur est inaccessible, veuillez contacter votre administrateur."
    },
    language: {
        "--": "Automatique",
        "zh": "中文",
        "en": "English",
        "fr": "Français",
        "ja": "日本語"
    },
    login: {
        "failed": "Le compte ou le mot de passe est incorrect, veuillez confirmer et vous reconnecter.",
        "status": {
            "0":"Votre compte est en cours d'examen, veuillez réessayer plus tard.\n\nVeuillez contacter votre administrateur si vous avez des questions."
        }
    }
}

export default {
    messages,
    antdLocale
}