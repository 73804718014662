// import LayoutTLR from '@/framework/layout/layoutTLR'

const routes = [
    {
        path: '/login',
        component: () => import('@/views/login-page.vue'),
    },
    {
        path: '/member',
        component: () => import('@/views/login-page.vue'),
    },
    {
        path: '/main',
        component: () => import('@/views/dashboard-page.vue'),
    },
    {
        path: '/logout',
        component: () => import('@/views/logout-page.vue'),
    },
    {
        path: '/error',
        component: () => import('@/views/error-page.vue'),
    },
    {
        path: '/download',
        component: () => import('@/views/download-page.vue'),
    },

]

export default routes
