import antdLocale from 'ant-design-vue/es/locale/en_US';

const messages = {
    http: {
        "undefined": "Requesting timeout, please check your network and try again.",
        "400": "[400] There was a problem with the request, please check your browser and network, or contact your administrator.",
        "401": "[401] You are not currently logged in or your login has timed out, please try again.",
        "403": "[403] Access denined, please contact your administrator.",
        "404": "[404] The resource requested does not exist, please contact your administrator.",
        "406": "[406] The format of request is invalid, please check your browser or contact your administrator.",
        "500": "[500] An error occurred on the server, please contact your administrator to resolve it.",
        "502": "[502] Gateway error, the network with the server is unreachable, please contact your administrator.",
        "503": "[503] The service is unavailable, server is temporarily overloaded or under maintenance, please try again later.",
        "504": "[504] The gateway timed out and the network with the server is unreachable, please contact your administrator."
    },
    language: {
        "--": "Auto",
        "zh": "中文",
        "en": "English",
        "fr": "Français",
        "ja": "日本語"
    },
    login: {
        "failed": "The account or password is incorrect, please confirm and try again.",
        "status": {
            "0": "Your account is under review, please try again later\n\nIf you have any questions please contact your administrator"
        }
    },
    "title": {
        "main": "main",
    }
}

export default {
    messages,
    antdLocale
}
