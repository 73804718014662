const menu = [
    // {
    //     name: "dashboard",
    //     url: "/dashboard",
    //     icon: "icon-jinrikanban",
    // }, {
    //     name: "schedule",
    //     url: "/schedule",
    //     icon: "icon-paibanguanli",
    // }, {
    //     name: "events",
    //     icon: "icon-shijianguanli",
    //     url: "/events",
    //     children: [
    //         {
    //             name: "events-publish",
    //             url: "/publish",
    //         }, {
    //             name: "events-inbox",
    //             url: "/inbox",
    //         }, {
    //             name: "events-outbox",
    //             url: "/outbox",
    //         }
    //     ]
    // }, {
    //     name: "groups",
    //     url: "/groups",
    //     icon: "icon-zuzhiguanli",
    // }, {
    //     name: "users",
    //     url: "/users",
    //     icon: "icon-yonghuguanli",
    // }
]

const appConfig = {
    menu,
    locale: {
        main: "zh",
        available: ["zh", "en", "fr"]
    },
}
export default appConfig


