import antdLocale from 'ant-design-vue/es/locale/ru_RU';

const messages = {
    http: {
        "undefined": "Время ожидания сетевого запроса истекло, проверьте сеть и повторите попытку.",
        "400": "[400] В сделанном запросе произошла ошибка, проверьте браузер и сеть или обратитесь к администратору.",
        "401": "[401] Вы не вошли в систему или время входа истекло, пожалуйста, войдите снова.",
        "403": "[403] В настоящее время у вас нет доступа к этой функции. Если у вас есть какие-либо вопросы, обратитесь к администратору.",
        "404": "[404] Запрашиваемый ресурс не существует, обратитесь к администратору.",
        "406": "[406] Запрошенный формат недействителен, проверьте браузер или обратитесь к администратору.",
        "500": "[500] Произошла ошибка на сервере, обратитесь к администратору для ее устранения.",
        "502": "[502] Ошибка шлюза, сеть с сервером недоступна, обратитесь к администратору.",
        "503": "[503] Сервис недоступен. Сервер временно перегружен или находится на техническом обслуживании. Повторите попытку позже.",
        "504": "[504] Время ожидания шлюза истекло, и сеть с сервером недоступна, обратитесь к администратору."
    },
    language: {
        "--": "автоматически",
        "zh": "中文",
        "en": "English",
        "fr": "Français",
        "ja": "日本語"
    },
    login: {
        "failed": "Учетная запись или пароль неверны, пожалуйста, подтвердите и войдите снова.",
        "status": {
            "0":"Ваш аккаунт находится на рассмотрении, повторите попытку позже.\n\nПожалуйста, свяжитесь с вашим администратором, если у вас есть какие -либо вопросы."
        }
    }
}

export default {
    messages,
    antdLocale
}