import storage from 'store'
import appConfig from '../config'
// import { useRouter, useRoute } from "vue-router";

export const permission = (router) => {
    const loginPath = appConfig.router.loginPath ?? '/login'
    const defultPath = appConfig.router.defultPath ?? '/'
    // const route = useRoute();
    // console.log(router.options.routes);
    const routerList = router.options.routes;
    router.beforeEach((to, from, next) => {
        // console.log(to.query?.type);
        // console.log(from);
        // console.log(next);
        const toPath = to.path;
        const pathQuery = to.query?.type;
        // if (storage.get('token')) {
        //     if (to.path === loginPath) {
        //         next({ path: defultPath })
        //     } else {
        //         if (to.meta?.title) document.title = to.meta.title
        //         next();
        //     }
        // } else {
        //     const path = routerList.find((item) => item.path == toPath);
        //     if (path) {
        //         next();
        //     } else {
        //         next({ path: "/error" });
        //     }
        //     // alert("111");
        //     // if (to.path === loginPath || (to.meta.auth?.length ?? 0) == 0) {
        //     //     alert("111");
        //     //     if (to.meta?.title) document.title = to.meta.title
        //     //     next();
        //     // } else {
        //     //     next({ path: loginPath, query: { redirect: to.fullPath } })
        //     // }
        // }
        // 先判断地址是否正确-->判断是否有token
        const path = routerList.find((item) => item.path == toPath);
        if (path) {
            const token = sessionStorage.getItem("token");
            if (token) {
                next();
            } else {
                if (toPath == "/main") {
                    if (pathQuery) {
                        next({ path: "/error" });
                    } else {
                        next({ path: "/login" });
                    }

                } else {
                    next();
                }
            }

        } else {
            next({ path: "/error" });
        }
    })


}
