import antdLocale from 'ant-design-vue/es/locale/ko_KR';

const messages = {
    http: {
        "undefined": "네트워크 요청 시간이 초과되었습니다. 네트워크를 확인하고 다시 시도하십시오.",
        "400": "[400] 오류가 발생했습니다. 브라우저 및 네트워크를 확인하거나 관리자에게 문의하십시오.",
        "401": "[401] 현재 로그인하지 않았거나 로그인 시간이 초과되었습니다. 다시 로그인하십시오.",
        "403": "[403] 현재 이 기능에 접근할 수 있는 권한이 없습니다. 문제가 있으면 관리자에게 연락하십시오.",
        "404": "[404] 요청한 자원이 없습니다. 관리자에게 문의하십시오.",
        "406": "[406] 요청한 형식이 잘못되었습니다. 브라우저를 확인하거나 관리자에게 문의하십시오.",
        "500": "[500] 서버에 오류가 발생했습니다. 관리자에게 문의하십시오.",
        "502": "[502] 게이트웨이 오류, 서버의 네트워크에 도달할 수 없습니다. 관리자에게 문의하십시오.",
        "503": "[503] 서비스를 사용할 수 없습니다. 서버가 잠시 과부하되거나 유지 보수 중입니다. 잠시 후에 다시 시도하십시오.",
        "504": "[504] 게이트웨이 시간이 초과되어 서버의 네트워크에 도달할 수 없습니다. 관리자에게 문의하십시오."
    },
    language: {
        "--": "자동 선택",
        "zh": "中文",
        "en": "English",
        "fr": "Français",
        "ja": "日本語"
    },
    login: {
        "failed": "계좌번호나 비밀번호가 정확하지 않으니 확인하고 다시 로그인하세요.",
        "status": {
            "0":"당신의 계정은 심사 중입니다. 잠시 후에 다시 시도하십시오.\n\n궁금한 점이 있으면 관리자에게 문의하십시오."
        }
    }
}

export default {
    messages,
    antdLocale
}