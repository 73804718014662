import antdLocale from 'ant-design-vue/es/locale/es_ES';

const messages = {
    http: {
        "undefined": "Se agotó el tiempo de espera de la solicitud de red, compruebe la red y vuelva a intentarlo.",
        "400": "[400] Hubo un error en la solicitud realizada, verifique su navegador y red, o comuníquese con su administrador.",
        "401": "[401] Actualmente no ha iniciado sesión o se ha agotado el tiempo de inicio de sesión, vuelva a iniciar sesión.",
        "403": "[403] Actualmente no tiene acceso a esta función, comuníquese con su administrador si tiene alguna pregunta.",
        "404": "[404] El recurso solicitado no existe, comuníquese con su administrador.",
        "406": "[406] El formato solicitado no es válido, verifique su navegador o comuníquese con su administrador.",
        "500": "[500] Ocurrió un error en el servidor, comuníquese con su administrador para resolverlo.",
        "502": "[502] Error de puerta de enlace, no se puede acceder a la red con el servidor, comuníquese con su administrador.",
        "503": "[503] El servicio no está disponible. El servidor está temporalmente sobrecargado o en mantenimiento. Vuelva a intentarlo más tarde.",
        "504": "[504] Se agotó el tiempo de espera de la puerta de enlace y no se puede acceder a la red con el servidor. Póngase en contacto con su administrador."
    },
    language: {
        "--": "Automático",
        "zh": "中文",
        "en": "English",
        "fr": "Français",
        "ja": "日本語"
    },
    login: {
        "failed": "La cuenta o la contraseña son incorrectas, confirme e inicie sesión nuevamente.",
        "status": {
            "0":"Su cuenta está en proceso de revisión, inténtelo de nuevo más tarde.\n\nSi tiene alguna pregunta, póngase en contacto con su administrador."
        }
    }
}

export default {
    messages,
    antdLocale
}